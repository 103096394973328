<template>
  <div style="padding:0;">
    <active-tab :activeTab="activeTab"/>

    <PageLoader
      style="margin-top: -60px;"
      v-if="pageLoading"
      :loading="pageLoading">
    </PageLoader>

    <div v-else>
      <div class="container_margin">
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col-10 align-self-center">
                <h4 class="font-weight-bold align-self-center">Clients</h4>
              </div>
              <div class="col-2 mb-2">
                <button @click="addclient" class="add-button">
                  <span class="material-symbols-rounded me-1">add</span>
                  Add Client
                </button>
              </div>
            </div>
    
            <div class="card border shadow-sm p-3 mt-2 rounded">
              <div class="row">
                <div
                  v-if="clients_list.length !== 0 || searchQuery.length !== 0"
                  class="col-5 mb-2"
                >
                  <div id="searchCaseLaw" class="input-group">
                    <input
                      v-model="searchQuery"
                      type="search"
                      class="form-control"
                      placeholder="Search by client email"
                      aria-label="Search by client email"
                      @input="onInput"
                      @keyup.enter="searchClients"
                    />
                    <span
                      @click="searchClients"
                      class="input-group-text cursor-pointer"
                      ><b-icon icon="search" font-scale="1.2"></b-icon
                    ></span>
                  </div>
                </div>
    
                <div class="col-7 d-flex justify-content-end">
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    last-number
                  ></b-pagination>
                </div>
              </div>
    
              <div class="row mt-2 table-card">
                <div class="col">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" width="7%">{{ "Status" }}</th>
    
                        <th scope="col" width="25%">{{ "Email" }}</th>
    
                        <th scope="col" width="10%" class="text-center">
                          {{ "Created Date" }}
                        </th>
                        <th scope="col" width="10%" class="text-center">
                          {{ "Expiry Date" }}
                        </th>
                        <th scope="col" width="19%" class="text-center">
                          {{ "Plan" }}
                        </th>
                        <th scope="col" width="15%" style="padding-left: 30px">
                          {{ "Label" }}
                        </th>
                        <th
                          scope="col"
                          width="10%"
                          class="text-center"
                          style="padding-right: 25px"
                        >
                          {{ "Action" }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <PageLoader
                        class="w-100"
                        v-if="pageLoading"
                        :loading="pageLoading">
                      </PageLoader>
    
                      <tr
                        v-else
                        v-for="(item, index) in clients_list"
                        :key="'ner_list' + index"
                      >
                        <td width="7%">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input float-right"
                              type="checkbox"
                              role="switch"
                              v-model="item.switch"
                              @change="changeStatus(item.id, item.switch)"
                            />
                            <label class="form-check-label"> </label>
                          </div>
                        </td>
                        <td width="25%">
                          {{ item.manager_email }}
                        </td>
                        <td width="10%" class="text-center">
                          {{ item.created_at | formatDate }}
                        </td>
                        <td width="10%" class="text-center">
                          {{ item.expiry_date | formatDate }}
                        </td>
                        <td width="19%" class="text-center">
                          {{ item.plan_name }}
                        </td>
    
                        <td width="14%">
                          <div class="row">
                            <div class="col">
                              <!-- <div
                                v-if="item.labels === null"
                                id="clientPill"
                                class="pill-container"
                              >
                                <span class="pill">
                                  {{ "No Label" }}
                                </span>
                              </div> -->
    
                              <div
                                v-if="item.labels !== null"
                                id="clientPill"
                                class="pill-container"
                              >
                                <span
                                  v-for="(label, index) in item.labels.filter(
                                    (label) => label !== null
                                  )"
                                  :key="'label' + index"
                                  class="pill"
                                >
                                  {{ label }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
    
                        <td width="10%" class="text-center">
                          <button
                            @click="editclient(item.id)"
                            class="edit-button"
                            id="editClient"
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
  </template>
  
  <script>
  /* eslint-disable */
  import PageLoader from "../../Pages/PageLoader.vue";
  import Settings from "../../store/Settings.js";
  import activeTab from "./activeTab.vue";
  
  export default {
    components: {
      PageLoader,
      activeTab,
    },
  
    data() {
      return {
        pageLoading: true,
        clients_list: [],
        searchQuery: "",
        rows: 100,
        perPage: 10,
        page: 0,
        currentPage: 1,
        activeTab: "clientTable",
      };
    },
  
    methods: {
      onInput(value) {
        // console.log("Im in onInput method");
        // console.log("The val: ", value);
  
        if (this.searchQuery.length === 0) {
          this.searchQuery = "";
          this.searchClients();
        }
      },
  
      searchClients() {
        // console.log("Im in searchClients method");
        this.currentPage = 1;
        this.pageLoading = true;
        this.clients_list = [];
  
        if (this.searchQuery.length !== 0) {
          this.$router.push({
            query: { page: this.currentPage, email: this.searchQuery },
          });
  
          Settings.GetClients(
            `?page=${this.currentPage}&manager_email=${this.searchQuery}`
          )
            .then((response) => {
              this.pageLoading = false;
              this.rows = response.data.data.total;
  
              response.data.data.data.forEach((element) => {
                if (element.active == 1) {
                  element.switch = true;
                } else {
                  element.switch = false;
                }
                this.clients_list.push(element);
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.$router.push({
            query: { page: this.currentPage },
          });
  
          Settings.GetClients(`?page=${this.currentPage}`)
            .then((response) => {
              this.pageLoading = false;
              this.rows = response.data.data.total;
  
              response.data.data.data.forEach((element) => {
                if (element.active == 1) {
                  element.switch = true;
                } else {
                  element.switch = false;
                }
                this.clients_list.push(element);
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
  
      changeStatus(id, status) {
        console.log(id, status);
  
        if (status) {
          Settings.activateClient(id)
            .then((response) => {
              this.$toast.success("Updated");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          Settings.deactivateClient(id)
            .then((response) => {
              this.$toast.success("Updated");
            })
            .catch((error) => {
              console.log(error);
            });
        }
  
        // this.Employees.forEach((element) => {
        //   if (element.id == data.id) {
        //     //
  
        //     if (element.statusActive == false) {
        //       // element.statusActive = false;
  
        //       delete element.statusActive;
  
        //       Saas.removeSaaSClient(element.id)
  
        //         .then(() => {
        //           this.Employees = [];
        //           this.page = 0;
        //           this.addData();
        //         })
        //         .catch((err) => {
        //           console.error(err);
        //         });
        //     } else {
        //       // //
        //       // element.statusActive = true;
  
        //       delete element.statusActive;
  
        //       Saas.ActivateSaaSClient(element.id)
  
        //         .then(() => {
        //           this.Employees = [];
        //           this.page = 0;
        //           this.addData();
        //         })
        //         .catch((err) => {
        //           console.error(err);
        //         });
        //     }
        //   }
        // });
      },
  
      addclient() {
        // Implement your logic to add an client
        this.$router.push({
          name: "clientadd",
        });
      },
  
      editclient(index) {
        // Implement your logic to edit an client
        this.$router.push({
          name: "clientedit",
          params: {
            id: index,
          },
        });
      },
    },
  
    watch: {
      currentPage(newPage, oldPage) {
        if (newPage != this.$route.query.page) {
          this.pageLoading = true;
          this.clients_list = [];
  
          if (this.searchQuery.length !== 0) {
            this.$router.push({
              query: { page: newPage, email: this.searchQuery },
            });
            // console.log("Router", this.$router)
  
            Settings.GetClients(
              `?page=${newPage}&manager_email=${this.searchQuery}`
            )
              .then((response) => {
                this.pageLoading = false;
                this.rows = response.data.data.total;
  
                response.data.data.data.forEach((element) => {
                  if (element.active == 1) {
                    element.switch = true;
                  } else {
                    element.switch = false;
                  }
                  this.clients_list.push(element);
                });
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.$router.push({
              query: { page: newPage },
            });
            // console.log("Router", this.$router)
  
            Settings.GetClients(`?page=${newPage}`)
              .then((response) => {
                this.pageLoading = false;
                this.rows = response.data.data.total;
  
                response.data.data.data.forEach((element) => {
                  if (element.active == 1) {
                    element.switch = true;
                  } else {
                    element.switch = false;
                  }
                  this.clients_list.push(element);
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      },
    },
  
    created() {
      const page = this.$route.query.page;
      const email = this.$route.query.email;
  
      if (page) {
        this.currentPage = parseInt(page, 10);
        this.clients_list = [];
      }
  
      if (email) {
        this.searchQuery = email;
      }
  
      if (this.searchQuery.length !== 0) {
        Settings.GetClients(
          `?page=${this.currentPage}&manager_email=${this.searchQuery}`
        )
          .then((response) => {
            this.pageLoading = false;
            this.rows = response.data.data.total;
  
            response.data.data.data.forEach((element) => {
              if (element.active == 1) {
                element.switch = true;
              } else {
                element.switch = false;
              }
              this.clients_list.push(element);
            });
          })
          .catch((error) => {
            console.log(error);
          });
        console.log(this.clients_list);
      } else {
        Settings.GetClients(`?page=${this.currentPage}`)
          .then((response) => {
            this.pageLoading = false;
            this.rows = response.data.data.total;
  
            response.data.data.data.forEach((element) => {
              if (element.active == 1) {
                element.switch = true;
              } else {
                element.switch = false;
              }
              this.clients_list.push(element);
            });
          })
          .catch((error) => {
            console.log(error);
          });
        console.log(this.clients_list);
      }
    },
  };
  </script>
  
  <style scoped>
  .table {
    border: none;
    background: white;
    table-layout: fixed;
  }
  
  tbody {
    display: block;
    max-height: auto;
    overflow-y: scroll;
  }

  .add-button {
  display: flex;
  align-items: center;
  background-color: var(--primary);
  color: #fff;
  padding: 5px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-button:hover {
  background: var(--Primary-Dark-Blue, #000046);
}
  

  .edit-button {
  background-color: var(--primary);
  color: #fff;
  padding: 2px 25px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-button:hover {
  background: var(--Primary-Dark-Blue, #000046);
}
  
  thead {
    display: table;
    width: 100%;
    table-layout: fixed;
    overflow-x: auto;
    border-bottom: 0.1vw solid #ddd !important;
    background: white;
  }
  
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    overflow-x: auto;
    border: none;
    background: white;
  }
  
  td {
    border-bottom: 0.001px solid #ddd;
    border-top: none;
    vertical-align: top;
  }
  
  th {
    border: none;
    color: #094890;
  }
  
  .container_margin {
    padding: 2rem;
  }
  
  .form-check-input:checked {
    border-color: #094890;
    background-color: #094890;
  }
  
  .form-switch .form-check-input {
    box-shadow: #094890;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23094890'/%3e%3c/svg%3e");
  }
  
  .form-switch .form-check-input:focus:checked {
    box-shadow: #094890;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
  }
  
  .form-switch .form-check-input:checked {
    box-shadow: #094890;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
  }
  
  .form-switch .form-check-input:focus {
    box-shadow: #094890;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23094890'/%3e%3c/svg%3e");
  }
  
  @media only screen and (max-width: 1024px) {
    .container_margin {
      padding: 0.5rem;
    }
  
    #editClient {
      /* padding-top: 5px !important;
      padding-bottom: 53px !important; */
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  </style>
  