<template>
    <div class="top-side" style="">
      <p class="header">My Profile</p>
       <div class="tabs">
        <router-link 
        to="/settings/profile" 
        class="tab" 
        :class="{ active: activeTab === 'profile' }" 
        @click.native="activeTab = 'profile'">
        Profile
        </router-link>

        <router-link 
        to="/settings/account_info" 
        class="tab" 
        :class="{ active: activeTab === 'accountInfo' }" 
        @click.native="activeTab = 'accountInfo'">
        Account Info
        </router-link>

        <router-link 
        to="/settings/clients" 
        v-if="hasSaasClientPermission" 
        class="tab" 
        :class="{ active: activeTab === 'clientTable' }">
        Clients
        </router-link>
      </div>
    </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  props: {
    activeTab: {
      type: String,
      default: 'profile'
    }
  },
  computed: {
    hasSaasClientPermission() {
      const userInfo = localStorage.getItem('userInfo');
      if (userInfo) {
        const parsedUserInfo = JSON.parse(userInfo);
        return parsedUserInfo.permissions && parsedUserInfo.permissions.includes('Saas-Client.index');
      }
      return false;
    }
  },
}
</script>
<style scoped>
.top-side {
  height: 108px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  background: #FFFFFF;
}
.header{
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  padding-top: 15px;
}
.tabs {
  display: flex;
  gap: 20px;
  color: var(--dark-grey);
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px;
}

.tab {
  text-decoration: none;
  color: var(--primary);
   padding-bottom: 8px;
}

.tab.active {
  color: var(--primary);
  border-bottom: 2px solid var(--primary);
}

</style>